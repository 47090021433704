export const successStartResponse = {
  code: 200,
  success: true,
  session_token: 'foobar',
}

export const successPanelResponse = {
  code: 200,
  success: true,
}

export const errorGetPanelResponse = {
  success: false,
  code: 500,
  error_message: 'error',
}

export const successGetPanelResponse = {
  ...successPanelResponse,
  panel: {
    session_token: 'foobar',
    panel_token: 'foobar',
    components: [
      {
        kind: 19, // PARAGRAPH
        name: '',
        parameters: {
          emphasis: 'medium',
          is_html: 'false',
          text: '<b>Civil Process Balance Update Inquiry</b>',
        },
      },
      {
        kind: 4, // TEXT_FIELD
        name: 'agency_name',
        parameters: {
          is_html: 'false',
          label: 'Agency Name',
          min_length: '1',
          required: 'true',
        },
      },
      {
        kind: 25, // DROPDOWN
        name: 'organization',
        parameters: {
          label: 'Organization',
          required: 'true',
          placeholder: 'Select an issue',
        },
        items: [
          {
            label: 'square_inc',
            text: 'Square, Inc.',
            type: 1,
          },
          {
            label: 'cash_app',
            text: 'Cash App',
            type: 1,
          },
        ],
      },
      {
        kind: 4, // TEXT_FIELD
        name: 'debtor_name',
        parameters: {
          is_html: 'false',
          label: 'Debtor Name',
          min_length: '1',
          required: 'true',
        },
      },
      {
        kind: 25, // DROPDOWN
        name: 'request_type',
        items: [
          {
            label: 'additional_information',
            text: 'Additional information',
            type: 1,
          },
          {
            label: 'balance_inquiry',
            text: 'Balance inquiry',
            type: 1,
          },
          {
            label: 'payment_inquiry',
            text: 'Payment inquiry',
            type: 1,
          },
          {
            label: 'other',
            text: 'Other',
            type: 1,
          },
        ],
        parameters: {
          is_html: 'false',
          label: 'Request Type',
          min_length: '1',
          required: 'true',
          placeholder: 'Select an issue',
        },
      },
      {
        kind: 25, // DROPDOWN
        name: 'notice_type',
        parameters: {
          label: 'Notice Type',
          required: 'true',
          placeholder: 'Select an issue',
        },
        items: [
          {
            label: 'garnishment',
            text: 'Garnishment',
            type: 1,
          },
          {
            label: 'information_subpoena',
            text: 'Information subpoena',
            type: 1,
          },
          {
            label: 'marshal_levy',
            text: 'Marshal levy',
            type: 1,
          },
          {
            label: 'tax_levy',
            text: 'Tax levy',
            type: 1,
          },
          {
            label: 'ucc_lien',
            text: 'UCC lien',
            type: 1,
          },
        ],
      },
      {
        kind: 4, // TEXT_FIELD
        name: 'details_of_inquiry',
        parameters: {
          label: 'Details of Inquiry',
          multiline: 'true',
          required: 'true',
          min_length: '1',
          is_html: 'false',
        },
      },
      {
        kind: 4, // TEXT_FIELD
        name: 'user_supplied_name',
        parameters: {
          label: 'Your Name',
          required: 'true',
          min_length: '1',
          is_html: 'false',
        },
      },
      {
        kind: 4, // TEXT_FIELD
        name: 'email',
        parameters: {
          label: 'Email',
          required: 'true',
          min_length: '1',
          is_html: 'false',
        },
      },
      {
        kind: 19, // PARAGRAPH
        name: '',
        parameters: {
          emphasis: 'medium',
          is_html: 'false',
          text: "By clicking \"Submit\", you agree to Square's <a href='https://squareup.com/legal/ua'>Terms</a>, <a href='https://squareup.com/legal/privacy'>Privacy Notice</a>, and <a href='https://squareup.com/legal/sign'>E-Sign Consent</a>.",
        },
      },
      {
        kind: 8, // SUMBIT
        name: 'submit',
        parameters: {
          label: 'Submit',
          validate: 'true',
        },
      },
    ],
  },
}
