import '../lib/polyfills'

import { AppProps } from 'next/app'
import { BackgroundPresetProvider } from '@block/kettlebell-react'

import '../styles/globals.scss'
import Background from '../components/Background/Background'

if (process.env.NEXT_PUBLIC_MOCK_JEDI) {
  require('../clients/mocks')
}

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <BackgroundPresetProvider>
      <Background />
      <Component {...pageProps} />
    </BackgroundPresetProvider>
  )
}

export default MyApp
