import { HttpResponse, delay, http } from 'msw'

import { errorGetPanelResponse, successGetPanelResponse, successStartResponse, successPanelResponse } from './responses'

export const buildStartHander = (shouldFail = false) => {
  let status = 201
  let response: any = successStartResponse

  if (shouldFail) {
    status = 500
    response = errorGetPanelResponse
  }

  return http.post('/api/v1/jedi/session/start', async () => {
    await delay(1000)

    return new HttpResponse(JSON.stringify(response), {
      status,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  })
}

export const buildGetPanelHandler = (failFetch = false, failSubmission = false) => {
  let status = 201
  let response: any = successGetPanelResponse

  return http.post('/api/v1/jedi/session/get_panel', async ({ request }) => {
    const body: any = await request.json()

    if (body?.inputs?.length > 0) {
      status = failSubmission ? 500 : 201
      response = failSubmission ? errorGetPanelResponse : successPanelResponse
    } else if (failFetch) {
      status = 500
      response = errorGetPanelResponse
    }

    await delay(1000)

    return new HttpResponse(JSON.stringify(response), {
      status,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  })
}
