var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"hJKvkJ37SEJgc-gXvCfm3"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

let dsn = null
const enabled = ['staging', 'production'].includes(process.env.NEXT_PUBLIC_APP_ENV)

if (enabled) {
  dsn =
    process.env.NEXT_PUBLIC_SENTRY_DSN || 'https://0299c08c128641b39780bc07f2737ae7@o160250.ingest.sentry.io/6249596'
}

Sentry.init({
  environment: process.env.NEXT_PUBLIC_APP_ENV,
  dsn,
  enabled,
})
