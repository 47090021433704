import React from 'react'
import KettlebellBackground, { useBackground } from '@block/kettlebell-react'

export default function Background(): React.ReactElement | null {
  const { isWebGLAvailable } = useBackground()

  if (!isWebGLAvailable) {
    return null
  }

  return <KettlebellBackground data-testid="kettlebelBackground" className="background" />
}
